import AccountProvider from "components/common/Context/AccountContext"
import { NoData, NoDataAndWentWrongHandler } from "components/common/noData"
import { Table } from "components/common/table"
import { getExcelDataFromTableData } from "components/common/table/Table"
import { getServiceRequestDetails } from "extensions/usoc/api/service"
import PropTypes from "prop-types"
import React, { useCallback, useContext, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import useSWR from "swr"
import { useExtension } from "utils"
import { toStandardDateString } from "utils/date"
import { useUserAuth } from "utils/hooks"
import { isAnyAPIFailed } from "utils/nodata"

import ServiceModal from "./ServiceModal.js"

async function fetchCrDetails(auth, extension, projectId, params) {
  const host = extension?.config?.env?.clientApiGateway?.URL
  const promises = []
  for (const param of params) {
    promises.push(
      getServiceRequestDetails({ auth, host }, projectId, param).catch(
        (err) => {
          throw err
        }
      )
    )
  }
  const results = await Promise.all(promises)
  return results
}

const categoryMap = {
  "USER ACCESS CONTROL": "Access Management",
  "ACTIVE DIRECTORY": "Access Management",
  SERVICENOW: "Access Management",
  NETWORK: "Infrastructure Requests",
  SERVER: "Infrastructure Requests",
  ENVIRONMENT: "Infrastructure Requests",
  SECURITY: "Security",
  PATCHING: "Patching"
}

function ChangeRequestTable({
  state,
  dateRange,
  defaultSelectedStatus,
  defaultSelectedBreakdown
}) {
  const auth = useUserAuth()
  const extension = useExtension()
  const userName = useSelector((state) => {
    return state.user.email
  })
  const projectID = useSelector((state) => {
    return state.settings.currentProject.id
  })
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false)
  const [selectedServiceNumber, setselectedServiceNumber] = useState()
  const { projectBonding } = useContext(AccountProvider)
  const openStateParams = [
    `state=NEW,ASSESSMENT,MORE INFO REQUIRED,IN PROGRESS,WITH 3RD PARTY&start_date=${toStandardDateString(
      dateRange?.startDate
    )}&end_date=${toStandardDateString(dateRange?.endDate)}`
  ]

  const closedParams = [
    `state=RESOLVED,CLOSED&start_date=${toStandardDateString(
      dateRange?.startDate
    )}&end_date=${toStandardDateString(dateRange?.endDate)}`
  ]

  const params = openStateParams.concat(closedParams)

  const { data: srResult } = useSWR(
    () =>
      projectID
        ? params.map(
            (param) =>
              `/projects/${projectID}/get_service_request_details?${param}`
          )
        : null,
    () => fetchCrDetails(auth, extension, projectID, params),
    { suspense: true }
  )

  const awaitingStates = ["New", "Assess", "Authorize"]

  const openSRs = useMemo(() => {
    return srResult[0].data
  }, [srResult])

  const resolvedSRs = useMemo(() => {
    return srResult[1].data
  }, [srResult])

  function handleIncidentNoClick(serviceNum) {
    setselectedServiceNumber(serviceNum)
    setIsServiceModalOpen(true)
  }
  const headers = useMemo(
    () => [
      { key: "request_number", text: "Request No", showOnCollapsedCard: true },
      {
        key: "created_date",
        text: "Date of Creation",
        showOnCollapsedCard: true
      },
      { key: "request_state", text: "Stage", showOnCollapsedCard: true },
      { key: "priority", text: "Priority", showOnCollapsedCard: true },
      { key: "category", text: "Category", showOnCollapsedCard: true },
      { key: "sub_category", text: "Sub-Category" },
      { key: "account_name", text: "Account Name" },
      { key: "caller", text: "Caller" },
      { key: "short_description", text: "Short Desc", tooltipRequired: true }
    ],
    []
  )

  const getTableData = useCallback(
    (serviceRequests) =>
      serviceRequests.map((eachSRData) => ({
        _key: eachSRData.request_number,
        _onClick: !(
          projectBonding?.incident_e_bonded?.toLowerCase() !== "false"
        )
          ? () => handleIncidentNoClick(eachSRData.request_number)
          : null,
        request_number: {
          text: eachSRData.request_number
        },
        created_date: { text: eachSRData.created_date },
        request_state: { text: eachSRData.request_state },
        priority: { text: `P${eachSRData.priority[0]}` },
        category: {
          text: Object.prototype.hasOwnProperty.call(
            categoryMap,
            eachSRData.category
          )
            ? categoryMap[eachSRData.category]
            : eachSRData.category
        },
        sub_category: { text: eachSRData.sub_category },
        account_name: { text: eachSRData.account_name || "Not specified" },
        caller: { text: eachSRData.caller },
        short_description: {
          text: eachSRData.short_description
            ? eachSRData.short_description
            : "N/A"
        }
      })),
    []
  )

  const data = useMemo(() => {
    let srTableData = []
    if (state.includes("Awaiting")) {
      srTableData = openSRs.filter((data) =>
        awaitingStates.includes(data.request_state)
      )
    } else if (state.includes("Breakdown")) {
      srTableData = [...openSRs, ...resolvedSRs]
    } else if (state === "Open") {
      srTableData = openSRs
    } else if (state === "Resolved") {
      srTableData = resolvedSRs
    } else {
      srTableData = [...openSRs, ...resolvedSRs].filter(
        (ticket) => ticket.caller_email === userName
      )
    }
    console.log(openSRs)
    return getTableData(srTableData)
  }, [getTableData, openSRs, resolvedSRs, state, userName])

  return (
    <>
      <NoDataAndWentWrongHandler wentWrongCondition={isAnyAPIFailed(srResult)}>
        <Table
          headers={headers}
          data={data}
          defaultSortKey="created_date"
          defaultSelectedTags={{
            ...(defaultSelectedBreakdown?.length
              ? { sub_category: defaultSelectedBreakdown }
              : {}),
            ...(defaultSelectedStatus?.length
              ? { request_state: defaultSelectedStatus }
              : {})
          }}
          excelFilename={
            ["Open", "Resolved", "My Tickets"].includes(state)
              ? "Incidents"
              : state
          }
          customExcelDatasets={
            ["Open", "Resolved", "My Tickets"].includes(state)
              ? [
                  getExcelDataFromTableData(
                    "Open",
                    headers,
                    getTableData(openSRs)
                  ),
                  getExcelDataFromTableData(
                    "Resolved",
                    headers,
                    getTableData(resolvedSRs)
                  ),
                  getExcelDataFromTableData(
                    "My Tickets",
                    headers,
                    getTableData(
                      [...openSRs, ...resolvedSRs].filter(
                        (ticket) => ticket.opened_by_email === userName
                      )
                    )
                  )
                ]
              : null
          }
          noDataComponent={<NoData />}
        />
      </NoDataAndWentWrongHandler>
      <ServiceModal
        show={isServiceModalOpen}
        setShow={setIsServiceModalOpen}
        serviceNumber={selectedServiceNumber}
      />
    </>
  )
}

export default ChangeRequestTable
ChangeRequestTable.propTypes = {
  state: PropTypes.string,
  dateRange: PropTypes.object,
  defaultSelectedStatus: PropTypes.array,
  defaultSelectedBreakdown: PropTypes.array
}
